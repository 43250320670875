<template>
  <PageContainer
    :title="$translations.profile.sections['about-me']"
    :back="actions.close"
    :has-shy-header="true"
  >
    <template v-slot:content>
      <template v-if="loading">
        <div class="page-segment">
          <div class="page-segment-content">
            <Skeleton
              class="margin-y"
              width="100%"
              height="20px"
              borderRadius="16px"
            />
            <Skeleton
              class="margin-bottom"
              width="100%"
              height="72px"
              borderRadius="16px"
            />
            <Skeleton
              class="margin-bottom"
              width="100%"
              height="100px"
              borderRadius="16px"
            />
          </div>
        </div>
      </template>

      <template v-else>
        <div class="page-segment">
          <div class="page-segment-content">
            <LabelAction
              :label="$translations.profile.sections['about-me']"
              :action="labelActionText"
              @clicked="update"
            />
            <div class="body" v-text="$translations.profile['about-me'].description" />
            <TextareaField
              class="margin-top"
              v-model="form.bio"
              :label="$translations.profile['textarea-placeholder']"
              :value="form.bio"
              :disabled="!showForm"
              :max-length="350"
              @change="setForm"
            />
          </div>
        </div>
      </template>
    </template>
  </PageContainer>
</template>

<script>
import {
  PageContainer,
  Specialists,
  Skeleton
} from '@seliaco/red-panda'
import { TextareaField } from '@seliaco/sea-otter'
import LabelAction from '@/components/LabelAction'

export default {
  name: 'AboutMeForm',
  components: {
    PageContainer,
    LabelAction,
    TextareaField,
    Skeleton
  },
  data () {
    return {
      loading: true,
      form: {
        bio: null
      },
      specialist: null,
      showForm: false,
      actions: {
        close: {
          callback: () => this.$router.push({ name: 'Profile' })
        },
        submit: {
          text: this.$translations.general.save,
          callback: () => this.update(),
          loading: false
        }
      }
    }
  },
  created () {
    this.getSpecialist()
  },
  methods: {
    getSpecialist () {
      this.loading = true
      Specialists.getBySpecialistId(
        this.$store.getters['auth/specialist'].id
      ).then((response) => {
        this.specialist = response
        this.form.bio = this.specialist.bio
        this.loading = false
      })
    },
    setForm (value) {
      this.form.bio = value
    },
    update () {
      if (this.showForm) {
        Specialists.updateSpecialistBio(
          this.$store.getters['auth/specialist'].id,
          this.form
        )
          .then(() => {
            this.$toast({
              severity: 'success',
              text: this.$translations.success.updated
            })
          })
          .finally(() => {
            this.showForm = false
          })
      } else {
        this.showForm = true
      }
    }
  },
  computed: {
    labelActionText () {
      return this.showForm
        ? this.$translations.general.save
        : this.$translations.general.edit
    }
  }
}
</script>
